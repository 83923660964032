<template>
  <div class="animated fadeIn">
    <b-row>
      <b-col md="12">
        <b-card no-body>
          <b-card-header>
            <i class="icon-info"></i> Pedido de Venda
            <b-button
              size="sm"
              class="float-right"
              :disabled="veriricaStatusPedido"
              v-on:click="getOrcamentoPrint()"
              variant="primary"
            >
              <i class="fa fa-print"></i> Imprimir Proposta
            </b-button>
          </b-card-header>
          <b-card-body>
            <b-row
              class="mb-3 mt-3 step-status-container"
              :class="getCorTimeLine()"
            >
              <b-col md-12>
                <ul>
                  <li :class="{ active: this.localStatus == 'E' }">
                    <span v-if="this.pedido.status != 'L'"> Aguardando </span>
                    <span v-else> Cancelado </span>
                  </li>
                  <li :class="{ active: this.localStatus == 'I' }">
                    <span>Pedido Importado</span>
                  </li>
                  <li :class="{ active: this.localStatus == 'C' }">
                    <span>Pedido confirmado</span>
                  </li>
                  <li :class="{ active: this.localStatus == 'F' }">
                    <span>Pedido Faturado</span>
                  </li>
                  <li :class="{ active: this.localStatus == 'D' }">
                    <span>Pedido enviado</span>
                  </li>
                </ul>
              </b-col>
            </b-row>

            <b-row class="mb-3 mt-4">
              <b-col>
                <p>
                  <strong class="mr-1">Nº PEDIDO:</strong>
                  <span class="mr-2">{{
                    this.pedido.codigo_eive
                      ? this.pedido.codigo_eive
                      : this.pedido.codigo
                  }}</span>
                  <strong class="mr-1">DATA:</strong>
                  <span>{{ this.pedido.data_pedido | formatDate }}</span>
                  <br />
                  <strong class="mr-1">Filial:</strong>
                  <span>{{ getFilial(this.pedido.cd_filial) }}</span>
                  <br />
                  <strong class="mr-1">Cliente:</strong>
                  <span>{{ this.pedido.razao_social }}</span>
                  <br />
                  <strong class="mr-1">Forma de pagamento:</strong>
                  <span>{{ this.pedido.forma_pgto }}</span>
                  <br />
                  <strong class="mr-1">Condição de pagamento:</strong>
                  <span>{{ this.pedido.cond_pgto }}</span>
                  <br />
                  <strong class="mr-1">E-mail:</strong>
                  <span>{{ this.pedido.email_contato }}</span>
                  <br />
                  <strong class="mr-1">Telefone:</strong>
                  <span>
                    ({{ this.pedido.ddd_telefone_contato || "0" }})
                    {{ (this.pedido.telefone_contato || "0") | phone }}
                  </span>
                  <span v-if="this.pedido.codigo_transportadora">
                    <br />
                    <strong class="mr-1">Transportadora:</strong>
                    <span>
                      {{ this.pedido.codigo_transportadora }} -
                      {{ this.pedido.razao_social_transportadora }}
                    </span>
                    <br />
                    <strong class="mr-1">Vl. Frete: </strong>
                    <span> R$ {{ this.pedido.valor_frete | money }} </span>
                  </span>
                </p>
              </b-col>
            </b-row>
            <b-row class="mt-2">
              <b-col>
                <b-table-simple
                  class="w-100 table-responsive-xl font-12"
                  :hover="true"
                  :striped="true"
                  :small="true"
                  :responsive="true"
                  :bordered="true"
                  stacked="sm"
                >
                  <b-thead>
                    <b-tr>
                      <b-th class="text-center width_8">Cód. Produto</b-th>
                      <b-th class="text-center">Descrição</b-th>
                      <b-th class="text-center">Qtde.</b-th>
                      <b-th class="text-center">Vl.Unit.</b-th>
                      <b-th class="text-center">Margem</b-th>
                      <b-th class="text-center">IPI</b-th>
                      <b-th class="text-center">ST</b-th>
                      <b-th class="text-center">Vl.Unit.Bruto</b-th>
                      <b-th class="text-center">Vl.Total</b-th>
                      <b-th class="text-center">Prêmio</b-th>
                    </b-tr>
                  </b-thead>
                  <b-tbody>
                    <b-tr
                      v-for="item in this.pedido.itens"
                      :key="item.produto_codigo"
                    >
                      <b-td
                        class="text-right"
                        :stacked-heading="'Cód. Produto'"
                      >
                        {{ item.produto_codigo }}
                      </b-td>
                      <b-td :stacked-heading="'Descrição'">
                        {{ item.titulo }}
                      </b-td>
                      <b-td class="text-right" :stacked-heading="'Qtde.'">
                        {{ item.quantidade }}
                      </b-td>
                      <b-td :stacked-heading="'Vl.Unit.'">
                        <div class="w-100 text-right moeda-real">
                          {{ item.valor_unitario | money }}
                        </div>
                      </b-td>
                      <b-td :stacked-heading="'Margem'">
                        <div class="w-100 text-right moeda-real">
                          {{ item.margem_valor | money }}
                        </div>
                      </b-td>
                      <b-td :stacked-heading="'IPI'">
                        <div class="w-100 text-right moeda-real">
                          {{ item.valor_ipi | money }}
                        </div>
                      </b-td>
                      <b-td :stacked-heading="'ST'">
                        <div class="w-100 text-right moeda-real">
                          {{ item.valor_st | money }}
                        </div>
                      </b-td>
                      <b-td :stacked-heading="'Vl.Unit.Bruto'">
                        <div class="w-100 text-right moeda-real">
                          {{ item.valor_bruto_unit | money }}
                        </div>
                      </b-td>
                      <b-td :stacked-heading="'Vl.Total'">
                        <div class="w-100 text-right moeda-real">
                          {{ item.valor_total | money }}
                        </div>
                      </b-td>
                      <b-td :stacked-heading="'Comissão'">
                        <div class="w-100 text-right moeda-real">
                          {{ item.valor_comissao | money }}
                        </div>
                      </b-td>
                    </b-tr>

                    <b-tr class="mt-5 mt-md-0">
                      <b-th class="d-none d-sm-table-cell">Totais</b-th>
                      <b-td class="border-none d-none d-sm-table-cell"></b-td>
                      <b-td class="border-none d-none d-sm-table-cell"></b-td>
                      <b-td class="border-none d-none d-sm-table-cell"></b-td>
                      <b-td class="border-none d-none d-sm-table-cell"></b-td>
                      <b-th :stacked-heading="'Total Vl.IPI'">
                        <div class="w-100 text-right moeda-real">
                          {{ this.pedido.total_ipi | money }}
                        </div>
                      </b-th>
                      <b-th :stacked-heading="'Total Vl.ST'">
                        <div class="w-100 text-right moeda-real">
                          {{ this.pedido.total_st | money }}
                        </div>
                      </b-th>
                      <b-th :stacked-heading="'Total Vl.Unt.Bruto'">
                        <div class="w-100 text-right moeda-real">
                          {{ this.pedido.total_valor | money }}
                        </div>
                      </b-th>
                      <b-th :stacked-heading="'Total Pedido'">
                        <div class="w-100 text-right moeda-real">
                          {{ this.pedido.total | money }}
                        </div>
                      </b-th>
                      <b-th :stacked-heading="'Total Comissão'">
                        <div class="w-100 text-right moeda-real">
                          {{ this.pedido.total_comissao | money }}
                        </div>
                      </b-th>
                    </b-tr>
                  </b-tbody>
                </b-table-simple>
              </b-col>
            </b-row>
            <b-row>
              <b-col sm="12" md="2" lg="2">
                Observação
                <button
                  class="icone-observacao sem-borda"
                  @click="exibe_observacao = !exibe_observacao"
                >
                  <i class="fa fa-plus"></i>
                </button>
              </b-col>
              <b-col sm="12" md="6" lg="6" class="mt-2 mb-2">
                <b-form-textarea
                  id="textarea"
                  v-model="observacao_text"
                  readonly
                  v-if="exibe_observacao"
                ></b-form-textarea>
              </b-col>
              <b-col
                sm="12"
                md="2"
                lg="2"
                offset-md="2"
                offset-lg="2"
                v-if="
                  this.pedido.nf_cancelada == 0 && this.pedido.status != 'E'
                "
              >
                <b-btn
                  variant="success"
                  v-if="!disabled"
                  v-on:click="exportar"
                  class="float-right"
                  >Detalhar Premiação</b-btn
                >
              </b-col>
            </b-row>
            <b-row>
              <b-col class="mt-2">
                <b-btn variant="primary" v-on:click="back" class="float-right"
                  >Voltar</b-btn
                >
                <b-btn
                  v-if="
                    this.pedido.nf_cancelada == 0 &&
                    (this.pedido.status == 'F' || this.pedido.status == 'D')
                  "
                  variant="primary"
                  v-on:click="$bvModal.show('modal-scoped')"
                  class="float-left"
                  >Informação Nota Fiscal</b-btn
                >
              </b-col>
            </b-row>
          </b-card-body>
        </b-card>
      </b-col>
    </b-row>
    <b-modal id="modal-scoped" title="Informação Nota Fiscal">
      <template>
        <b-card>
          <b-card-text>
            <strong class="float-left"
              >N° da Nota Fiscal: {{ this.pedido.numero_nf }}</strong
            >
            <strong class="float-right"
              >Data: {{ this.pedido.data_emissao_nf | formatDate }}</strong
            >
          </b-card-text>
        </b-card>
        <b-card
          border-variant="primary"
          header="Observações da Nota Fiscal"
          header-bg-variant="primary"
          header-text-variant="white"
          align="center"
        >
          <b-card-text>{{ this.pedido.observacao_nf }}</b-card-text>
        </b-card>
      </template>
      <template v-slot:modal-footer="{ cancel }">
        <div class="w-100">
          <b-link
            class="float-left mr-2"
            @click="downloadNota(downloadPdf, 'pdf')"
          >
            <b-button-group>
              <b-button variant="danger">
                <i class="fa fa-file-pdf-o"></i>
              </b-button>
              <b-button variant="danger">PDF</b-button>
            </b-button-group>
          </b-link>
          <b-link
            class="float-left mr-2"
            @click="downloadNota(downloadXml, 'xml')"
          >
            <b-button-group>
              <b-button variant="primary">
                <i class="fa fa-download"></i>
              </b-button>
              <b-button variant="primary">XML</b-button>
            </b-button-group>
          </b-link>
          <b-button class="float-right" variant="danger" @click="cancel()"
            >Cancelar</b-button
          >
        </div>
      </template>
    </b-modal>
  </div>
</template>

<style>
.font-12 {
  font-size: 12px;
}
.color-red {
  color: red;
}
.width_8 {
  width: 8%;
}
.icone-observacao {
  border: none !important;
  background: none !important;
  color: #3131bb;
}
.sem-borda:focus {
  border: none;
}
.border-none {
  border-top: none;
  border-right: none;
  border-left: none;
}
@media (min-width: 768px) {
  .step-status-icon-enabled {
    color: green;
  }
  .step-status-icon-disabled {
    color: lightgray;
  }
  .step-status-container li {
    list-style-type: none;
    float: left;
    min-width: 19%;
    position: relative;
    text-align: center;
  }

  .step-status-container li span {
    font-size: small;
  }

  .step-status-container li::before {
    content: "✔";
    height: 30px;
    width: 30px;
    line-height: 26px;
    border: 2px solid rgb(201, 201, 201);
    color: rgb(173, 173, 173);
    display: block;
    text-align: center;
    margin: 0 auto 10px auto;
    border-radius: 50%;
    background-color: white;
    z-index: 1;
    position: relative;
    font-family: Arial;
    font-weight: bolder;
  }
  .step-status-container li::after {
    content: "";
    position: absolute;
    width: 100%;
    height: 2px;
    background-color: rgb(201, 201, 201);
    top: 15px;
    left: -50%;
    z-index: 0;
  }

  .step-status-container li:first-child:after {
    content: none;
  }

  .step-status-container li.active {
    color: green;
  }
  .step-status-container li.active:before {
    border-color: green;
    background-color: green;
    color: white !important;
    font-family: Arial;
    font-weight: bolder;
  }
  .step-status-container li.active + li:after {
    background-color: rgb(201, 201, 201);
  }
}

@media (max-width: 767px) {
  .step-status-icon-enabled {
    color: green;
  }
  .step-status-icon-disabled {
    color: lightgray;
  }
  .step-status-container li {
    list-style-type: none;
    min-width: 24%;
    position: relative;
    text-align: center;
    margin-bottom: 3rem;
  }

  .step-status-container li span {
    font-size: medium;
  }

  .step-status-container li::before {
    content: "✔";
    height: 30px;
    width: 30px;
    line-height: 26px;
    border: 2px solid rgb(201, 201, 201);
    color: rgb(173, 173, 173);
    display: block;
    text-align: center;
    margin: 0 auto 10px auto;
    border-radius: 50%;
    background-color: white;
    z-index: 1;
    position: relative;
    font-family: Arial;
    font-weight: bolder;
  }
  .step-status-container li::after {
    content: "";
    position: absolute;
    width: 2px;
    height: 3rem;
    background-color: rgb(201, 201, 201);
    top: -2.5rem;
    left: 50%;
    z-index: 0;
  }

  .step-status-container li:first-child:after {
    content: none;
  }

  .step-status-container li.active {
    color: green;
  }
  .step-status-container li.active:before {
    border-color: green;
    background-color: green;
    color: white !important;
    font-family: Arial;
    font-weight: bolder;
  }
  .step-status-container li.active + li:after {
    background-color: rgb(201, 201, 201);
  }
}
</style>

<script>
import Pedido from "../../../services/pedido";
import Orcamento from "../../../services/orcamento";
import GrupoPermissao from "../../../services/grupoPermissao";

var vuejs;

export default {
  name: "Pedido",
  components: { Orcamento },
  data: () => ({
    localStatus: "",
    observacao_text: "",
    pedido: new Object(),
    codigo: null,
    exibe_observacao: false,
    disabled: false,
  }),
  mounted() {
    this.validaPermissao();
    vuejs = this;
  },
  computed: {
    downloadPdf() {
      return this.pedido.url_pdf_nf;
    },
    downloadXml() {
      return this.pedido.url_xml_nf;
    },
    veriricaStatusPedido() {
      return this.pedido.status == "L" ? true : false;
    },
  },
  beforeMount() {
    this.codigo = this.$route.params.codigo;
    this.loadOrder(this.codigo);
  },
  methods: {
    validaPermissao() {
      GrupoPermissao.getPermissao("/vendas/pedidos").then((response) => {
        if (!response.data.data) this.$router.push("/");
        else this.disabled = response.data.data.edita != "1";
      });
    },
    loadOrder(codigo) {
      this.pedido = new Object();
      Pedido.getByCodigo(codigo)
        .then((response) => {
          this.pedido = response.data.data;
          this.localStatus = this.pedido.status;
          this.observacao_text = response.data.data.observacao;
        })
        .catch((e) => {
          this.$helper.showErrorResponse(e);
        });
    },
    getCorTimeLine() {
      return this.pedido.status == "L" ? "color-red" : "";
    },
    getOrcamentoPrint() {
      if (this.pedido.status == "E") {
        this.printOrderSent();
      } else {
        this.printImportedOrder();
      }
    },
    printOrderSent() {
      Orcamento.printOrcamento(this.pedido.orcamento_id, "enviado")
        .then((response) => {
          vuejs.openPdf(response.data);
        })
        .catch((e) => {
          debugger;
          this.$helper.showErrorResponse(e);
        });
    },
    printImportedOrder() {
      Pedido.printPedido(this.codigo)
        .then((response) => {
          vuejs.openPdf(response.data);
        })
        .catch((e) => {
          this.$helper.showErrorResponse(e);
        });
    },
    getFilial(filial) {
      return filial == 1 ? "Maringá - PR" : "Itajaí - SC";
    },
    openPdf(data) {
      let fileURL = window.URL.createObjectURL(
        new Blob([data], {
          type: "application/pdf",
        })
      );
      let windowPrint = window.open(fileURL, "about:blank");
      windowPrint.onload = function () {
        windowPrint.print();
      };
    },
    back() {
      this.$router.go(-1);
    },
    exportar() {
      Pedido.exportarPlanilha(this.pedido.codigo)
        .then((response) => {
          window.open(
            process.env.VUE_APP_LOCAL +
              "/assets/documentos/" +
              response.data.data,
            "about:blank"
          );
        })
        .catch((e) => {
          this.$helper.showErrorResponse(e);
        });
    },
    downloadNota(endpoint, tipo_arquivo) {
      let chave = "";

      if (
        endpoint != "" &&
        tipo_arquivo == "pdf" &&
        endpoint.search("chave=")
      ) {
        chave = endpoint.split("chave=");
        chave = chave[1];
      } else if (
        endpoint != "" &&
        tipo_arquivo == "xml" &&
        endpoint.search("chaveXML=")
      ) {
        chave = endpoint.split("chaveXML=");
        chave = chave[1];
      }

      if (chave) {
        if (tipo_arquivo == "pdf") {
          Pedido.baixarNotaPdf(chave)
            .then((response) => {
              vuejs.criaUrlDownloadNota(response.data, tipo_arquivo);
            })
            .catch((e) => {
              this.$helper.showErrorResponse(e);
            });
        } else if (tipo_arquivo == "xml") {
          Pedido.baixarNotaXml(chave)
            .then((response) => {
              vuejs.criaUrlDownloadNota(response.data, tipo_arquivo);
            })
            .catch((e) => {
              this.$helper.showErrorResponse(e);
            });
        }
      }
    },
    criaUrlDownloadNota(data, tipo_arquivo) {
      var nome_arquivo = "";
      const url = window.URL.createObjectURL(new Blob([data]));
      const link = document.createElement("a");

      if (tipo_arquivo == "pdf") {
        if (!vuejs.pedido.numero_nf) {
          nome_arquivo = "docAuxiliar.pdf";
        } else {
          nome_arquivo = "docAuxiliar_" + vuejs.pedido.numero_nf + ".pdf";
        }
      } else if (tipo_arquivo == "xml") {
        nome_arquivo = "visualizacao_doc_auxiliar.xml";
      }

      link.href = url;
      link.setAttribute("download", nome_arquivo);
      document.body.appendChild(link);
      link.click();
    },
  },
};
</script>
